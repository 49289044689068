import React from "react";
import "./why-block.scss";
import {H1, H2, H3, H4, TextL} from "@mryaros/mryaros-design-system";
import {IconBell, IconCircleOk, IconCup, IconShield, IconThumbsUp, IconUser} from "../../components/icons/Icons";

export const WhyBlock = React.memo(function WhyBlock() {
    return (
        <div className="why-block flex-col-center">
            <div className="content">
                <H3 className="white"><b>ПОЧЕМУ МЫ?</b></H3>
                <div className="pluses flex-row-center flex-j-between">
                    <div style={{borderRight: "1px solid white", padding: "20px"}}>
                        <H1 className="margin-no">10</H1>
                        <TextL className="margin-no">лет на рынке</TextL>
                    </div>
                    <div style={{borderRight: "1px solid white", padding: "20px"}}>
                        <H1 className="margin-no">1000+</H1>
                        <TextL className="margin-no">объектов под охраной</TextL>
                    </div>
                    <div style={{borderRight: "1px solid white", padding: "20px"}}>
                        <TextL className="margin-no">Работаем по Пермскому краю, в Кировской, Свердловской и Челябинской областях, в Республике Коми</TextL>
                    </div>
                    <div style={{borderRight: "1px solid white", padding: "20px"}}>
                        <TextL className="margin-no">Стильная униформа, опрятный внешний вид сотрудников</TextL>
                    </div>
                    <div style={{borderRight: "1px solid white", padding: "20px"}}>
                        <TextL className="margin-no">Строим долгие и доверительные отношения с каждым клиентом.</TextL>
                    </div>
                    <div style={{padding: "20px"}}>
                        <TextL className="margin-no">Отличная спортивная подготовка бойцов</TextL>
                    </div>
                </div>
                <div className="flex-row flex-j-between flex-wrap margin-t-32 margin-all-b-24">
                    <div className="flex-row-center">
                        <IconUser />
                        <div className="margin-l-24">
                            <H4 className="margin-no white">Круглосуточная клиентская поддержка</H4>
                            <TextL className="margin-no white">Связь с дежурной частью 24/7</TextL>
                        </div>
                    </div>
                    <div className="flex-row-center">
                        <IconBell />
                        <div className="margin-l-24">
                            <H4 className="margin-no white">Круглосуточная клиентская поддержка</H4>
                            <TextL className="margin-no white">Связь с дежурной частью 24/7</TextL>
                        </div>
                    </div>
                    <div className="flex-row-center">
                        <IconCup />
                        <div className="margin-l-24">
                            <H4 className="margin-no white">Круглосуточная клиентская поддержка</H4>
                            <TextL className="margin-no white">Связь с дежурной частью 24/7</TextL>
                        </div>
                    </div>
                    <div className="flex-row-center">
                        <IconCircleOk />
                        <div className="margin-l-24">
                            <H4 className="margin-no white">Круглосуточная клиентская поддержка</H4>
                            <TextL className="margin-no white">Связь с дежурной частью 24/7</TextL>
                        </div>
                    </div>
                    <div className="flex-row-center">
                        <IconThumbsUp />
                        <div className="margin-l-24">
                            <H4 className="margin-no white">Круглосуточная клиентская поддержка</H4>
                            <TextL className="margin-no white">Связь с дежурной частью 24/7</TextL>
                        </div>
                    </div>
                    <div className="flex-row-center">
                        <IconShield />
                        <div className="margin-l-24" >
                            <H4 className="margin-no white">Круглосуточная клиентская поддержка</H4>
                            <TextL className="margin-no white">Связь с дежурной частью 24/7</TextL>
                        </div>
                    </div>
                </div>
                <H2 className="white ">Мы осуществляем охрану объектов различной категории сложности</H2>
                <div className="flex-row flex-j-between">
                    <div className="rooms flex-row-center">
                        <H4 className="margin-no">Офисы и офисные центры</H4>
                    </div>
                    <div className="rooms flex-row-center">
                        <H4 className="margin-no">Культурно-массовые мероприятия</H4>
                    </div>
                    <div className="rooms flex-row-center">
                        <H4 className="margin-no">Строительные и производственные объекты, склады</H4>
                    </div>
                    <div className="rooms flex-row-center">
                        <H4 className="margin-no">Образовательные учреждения</H4>
                    </div>
                    <div className="rooms flex-row-center">
                        <H4 className="margin-no">Магазины и торговые комплексы</H4>
                    </div>
                    <div className="rooms flex-row-center margin-b-64">
                        <H4 className="margin-no">Медицинские учреждения</H4>
                    </div>
                </div>
            </div>
        </div>
    );
});