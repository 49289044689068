import React from "react";
import {IconPhone, TextL, TypicalHeader} from "@mryaros/mryaros-design-system";
import BulatLogo from "../../static/logo.png";
import {Link} from "react-router-dom";

export const ApplicationHeader = React.memo(function ApplicationHeader() {

    const headerLinks = (<>
        <Link to="/#about">О КОМПАНИИ</Link>
        <Link to="/#services" className="link-block">УСЛУГИ</Link>
        <Link to="/#contacts" className="link-block">КОНТАКТЫ</Link>
    </>);

    const additionalBlock = (
        <div className="flex-row"><IconPhone className="margin-r-16 header"/><TextL className="margin-no"><b>+7 (342) 277-57-75</b></TextL></div>
    );
    return <TypicalHeader logoSrc={BulatLogo} headerLinks={headerLinks} additionalBlock={additionalBlock} className=""/>
})