import "./page.scss";
import {MainBlock} from "../main/MainBlock";
import {AboutBlock} from "../about/AboutBlock";
import {WhyBlock} from "../why/WhyBlock";
import {ServicesBlock} from "../services/ServicesBlock";
import {PartnersBlock} from "../partners/PartnersBlock";

export function Page() {
    return (
        <div className="page">
            <MainBlock />
            <AboutBlock />
            <WhyBlock />
            <ServicesBlock />
            <PartnersBlock />
        </div>
    )
}