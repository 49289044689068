import React from 'react';
import '../App.scss';
import {BrowserRouter} from "react-router-dom";
import {ModalProvider} from "@mryaros/mryaros-design-system";
import {ApplicationHeader} from "./components/ApplicationHeader";
import {ApplicationFooter} from "./components/ApplicationFooter";
import {Page} from "./pages/page/Page";
import {YandexMapBlock} from "./pages/yandexmap/YandexMapBlock";

function App() {
    return (
        <BrowserRouter>
            <ModalProvider>
                <div className="app">
                    <ApplicationHeader />
                    <Page />
                    <ApplicationFooter />
                    <YandexMapBlock />
            </div>
        </ModalProvider>
</BrowserRouter>
)
}

export default App;
