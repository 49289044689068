import React from "react";
import "./main-block.scss";
import {TextL} from "@mryaros/mryaros-design-system";

export const MainBlock = React.memo(function MainBlock() {
    return (
        <div className="main-block">
            <div className="big-name white">БУЛАТ</div>
            <TextL className="white">ООО ЧОО «БУЛАТ» - компания, оказывающая <br/> охранные услуги. Строим долгосрочные<br/> доверительные отношения с каждым клиентом.</TextL>
        </div>
    );
});