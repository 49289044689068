import React from "react";
import {H3, TextM} from "@mryaros/mryaros-design-system";
import AboutImage from "../../../static/about.png";

export const AboutBlock = React.memo(function AboutBlock() {
    return (
        <div className="flex-row-center flex-j-center" style={{width: "100%"}} id="about">
            <div className="content flex-row flex-align-center flex-j-between margin-t-120 margin-b-120">
                <div style={{maxWidth: "45%"}}>
                    <H3><b>О КОМПАНИИ</b></H3>
                    <TextM>Частная охранная организация «БУЛАТ» - это специализированная структура, которая предоставляет услуги по обеспечению безопасности физических и юридических лиц. Мы предоставляем физическую
                            и пультовую охрана различных объектов, разработку, внедрение
                            и обслуживание систем безопасности. Мы гарантируем защиту жизни
                            и здоровья граждан, обеспечение правопорядка при проведении культурно-массовых мероприятий, консультационные услуги по вопросам обеспечения правомерной защиты и многое другое.</TextM>

                </div>
                <img src={AboutImage} alt="AboutImage" className="margin-t-24 margin-b-24" style={{maxWidth: "45%", borderRadius: "22px"}}/>
            </div>
        </div>
    );
});