import React from "react";
import "./partners-block.scss";
import {H3, H4} from "@mryaros/mryaros-design-system";
import spk from "../../../static/partners/spk.png";
import chtptu from "../../../static/partners/chtptu.png";
import data_bank from "../../../static/partners/data_bank.png";
import ed_ros from "../../../static/partners/ed_ros.png";
import fns from "../../../static/partners/fns.png";
import heart from "../../../static/partners/heart.png";
import industrial_college from "../../../static/partners/industrial_college.png";
import lukoil from "../../../static/partners/lukoil.png";
import med_college from "../../../static/partners/med_college.png";
import molod_gvardia from "../../../static/partners/molod_gvardia.png";
import molod_parlament from "../../../static/partners/molod_parlament.png";
import neftehimprom from "../../../static/partners/neftehimprom.png";
import perm_park from "../../../static/partners/perm_park.png";
import pgmu from "../../../static/partners/pgmu.png";
import pobeda from "../../../static/partners/pobeda.png";
import razvitie from "../../../static/partners/razvitie.png";
import rosplazma from "../../../static/partners/rosplazma.png";
import rosseti from "../../../static/partners/rosseti.png";
import shin_zavod from "../../../static/partners/shin_zavod.png";
import soc_fond_rus from "../../../static/partners/soc_fond_rus.png";
import sovkombank from "../../../static/partners/sovkombank.png";
import svetofor from "../../../static/partners/svetofor.png";
import veteran_hospital from "../../../static/partners/veteran_hospital.png";

export const PartnersBlock = React.memo(function PartnersBlock(){
    console.log(require("../../../static/partners/pgmu.png"))
    return (
        <div className="partners-block flex-col-center" id="partners">
            <div className="content">
                <H3>Наши партнёры</H3>
                <H4 className="margin-t-16">Нашими клиентами являются <br/> лидеры рынка из разных отраслей</H4>
                <div className="margin-b-64 flex-row flex-j-between flex-wrap margin-all-b-32">
                    <PartnerBlock partnerSrc={spk} />
                    <PartnerBlock partnerSrc={chtptu} />
                    <PartnerBlock partnerSrc={data_bank} />
                    <PartnerBlock partnerSrc={ed_ros} />
                    <PartnerBlock partnerSrc={fns} />
                    <PartnerBlock partnerSrc={heart} />
                    <PartnerBlock partnerSrc={industrial_college} />
                    <PartnerBlock partnerSrc={lukoil} />
                    <PartnerBlock partnerSrc={med_college} />
                    <PartnerBlock partnerSrc={molod_gvardia} />
                    <PartnerBlock partnerSrc={molod_parlament} />
                    <PartnerBlock partnerSrc={neftehimprom} />
                    <PartnerBlock partnerSrc={perm_park} />
                    <PartnerBlock partnerSrc={pgmu} />
                    <PartnerBlock partnerSrc={pobeda} />
                    <PartnerBlock partnerSrc={razvitie} />
                    <PartnerBlock partnerSrc={rosplazma} />
                    <PartnerBlock partnerSrc={rosseti} />
                    <PartnerBlock partnerSrc={shin_zavod} />
                    <PartnerBlock partnerSrc={soc_fond_rus} />
                    <PartnerBlock partnerSrc={sovkombank} />
                    <PartnerBlock partnerSrc={svetofor} />
                    <PartnerBlock partnerSrc={veteran_hospital} />
                </div>
            </div>
        </div>
    );
});

type PartnerBlockType = {
    partnerSrc: string
};

const PartnerBlock = React.memo(function PartnerBlock({partnerSrc}: PartnerBlockType) {
    return (
        <div className="partner flex-col-center flex-j-center">
            <img src={partnerSrc} style={{maxWidth: "95%", maxHeight: "95%"}}/>
        </div>
    )
})