import React, {useContext} from "react";
import "./services-block.scss";
import {Button, EButtonWidth, H3, H4, ModalContext, TextL} from "@mryaros/mryaros-design-system";

export const ServicesBlock = React.memo(function ServicesBlock() {
    return (
        <div className="services-block flex-col-center" id="services">
            <div className="content">
                <H3 className="white"><b>НАШИ УСЛУГИ</b></H3>
                <div className="flex-row flex-j-between flex-wrap margin-all-b-24">
                    {services.map((service) =>
                        <ServiceBlock service={service}/>
                    )}
                </div>
            </div>
        </div>
    );
});

type ServiceBlockProps = {
    service: ServiceBlockType;
};

const ServiceBlock = React.memo(function ServiceBlock({service}: ServiceBlockProps) {
    const {pushModalWindow} = useContext(ModalContext);
    return (
        <div className="service flex-col flex-j-between">
            <H4 className="margin-no">{service.name}</H4>
            <div>
                <TextL className="margin-b-0">{service.priceName1}</TextL>
                <TextL className="margin-no">От <b>{service.price1}</b></TextL>
            </div>
            {service.price2 ? (
                <div>
                    <TextL className="margin-b-0">{service.priceName2}</TextL>
                    <TextL className="margin-no">От <b>{service.price2}</b></TextL>
                </div>
            ) : null}
            <Button
                className="margin-t-16"
                label="узнать подробнее"
                buttonWidth={EButtonWidth.FULL_WIDTH}
                onClick={() => pushModalWindow({
                    header: service.name,
                    body: (
                        <>
                            <b>{service.modalPrice1}</b><br />
                            {service.modalPrice2 ? (<b>{service.modalPrice2}</b>) : null}<br /><br />
                            {service.description}
                        </>
                    ),
                    buttons: [],
                })}
            />
        </div>
    );
});

type ServiceBlockType = {
    name: string;
    priceName1: string;
    price1: string;
    priceName2?: string;
    price2?: string;
    modalPrice1: string;
    modalPrice2?: string;
    description: string;
};

const services = [
    {
        name: "Охранная сигнализация",
        priceName1: "Абонентская плата:",
        price1: "1 000 ₽ / мес.",
        priceName2: "Оборудование:",
        price2: "7 000 ₽",
        modalPrice1: "Стоимость обслуживания от 1 000 руб./мес.",
        modalPrice2: "Стоимость оборудования от 7 000 руб.",
        description: "Обеспечение безопасности при помощи современных систем охранно-пожарной и тревожной сигнализации предполагает, в том числе, последующий выезд ГБР. После поступления сигнала тревоги на наш пульт круглосуточного наблюдения на объект будет отправлена группа быстрого реагирования. Она состоит из сотрудников с удостоверениями 6-го разряда (применение служебного огнестрельного оружия). Среднее время прибытия группы до 7 минут. При подключении вашего объекта к нашей дежурной части мы проведем бесплатный осмотр объекта, выявим все его уязвимые места и устраним их посредством установки современного оборудования, которое позволит зафиксировать несанкционированное проникновение. Мы применяем как радио-волновую, так и GSM-систему мониторинга, имеем собственный отдел монтажа и обслуживания охранно-пожарной сигнализации, системы контроля доступа, видеонаблюдения. Ежемесячный платеж за услуги охраны: Гаражи - 700 руб Квартиры - 1000 руб. Офисы, магазины - от 1500 руб. Коттеджи, частные дома - от 1500 руб.",
    } as ServiceBlockType,
    {
        name: "Охранная сигнализация",
        priceName1: "Абонентская плата:",
        price1: "1 000 ₽ / мес.",
        priceName2: "Оборудование:",
        modalPrice1: "Стоимость обслуживания от 1 000 руб./мес.",
        description: "Обеспечение безопасности при помощи современных систем охранно-пожарной и тревожной сигнализации предполагает, в том числе, последующий выезд ГБР. После поступления сигнала тревоги на наш пульт круглосуточного наблюдения на объект будет отправлена группа быстрого реагирования. Она состоит из сотрудников с удостоверениями 6-го разряда (применение служебного огнестрельного оружия). Среднее время прибытия группы до 7 минут. При подключении вашего объекта к нашей дежурной части мы проведем бесплатный осмотр объекта, выявим все его уязвимые места и устраним их посредством установки современного оборудования, которое позволит зафиксировать несанкционированное проникновение. Мы применяем как радио-волновую, так и GSM-систему мониторинга, имеем собственный отдел монтажа и обслуживания охранно-пожарной сигнализации, системы контроля доступа, видеонаблюдения. Ежемесячный платеж за услуги охраны: Гаражи - 700 руб Квартиры - 1000 руб. Офисы, магазины - от 1500 руб. Коттеджи, частные дома - от 1500 руб.",
    } as ServiceBlockType,
    {
        name: "Охранная сигнализация",
        priceName1: "Абонентская плата:",
        price1: "1 000 ₽ / мес.",
        priceName2: "Оборудование:",
        price2: "7 000 ₽",
        modalPrice1: "Стоимость обслуживания от 1 000 руб./мес.",
        modalPrice2: "Стоимость оборудования от 7 000 руб.",
        description: "Обеспечение безопасности при помощи современных систем охранно-пожарной и тревожной сигнализации предполагает, в том числе, последующий выезд ГБР. После поступления сигнала тревоги на наш пульт круглосуточного наблюдения на объект будет отправлена группа быстрого реагирования. Она состоит из сотрудников с удостоверениями 6-го разряда (применение служебного огнестрельного оружия). Среднее время прибытия группы до 7 минут. При подключении вашего объекта к нашей дежурной части мы проведем бесплатный осмотр объекта, выявим все его уязвимые места и устраним их посредством установки современного оборудования, которое позволит зафиксировать несанкционированное проникновение. Мы применяем как радио-волновую, так и GSM-систему мониторинга, имеем собственный отдел монтажа и обслуживания охранно-пожарной сигнализации, системы контроля доступа, видеонаблюдения. Ежемесячный платеж за услуги охраны: Гаражи - 700 руб Квартиры - 1000 руб. Офисы, магазины - от 1500 руб. Коттеджи, частные дома - от 1500 руб.",
    } as ServiceBlockType,
    {
        name: "Охранная сигнализация",
        priceName1: "Абонентская плата:",
        price1: "1 000 ₽ / мес.",
        priceName2: "Оборудование:",
        price2: "7 000 ₽",
        modalPrice1: "Стоимость обслуживания от 1 000 руб./мес.",
        modalPrice2: "Стоимость оборудования от 7 000 руб.",
        description: "Обеспечение безопасности при помощи современных систем охранно-пожарной и тревожной сигнализации предполагает, в том числе, последующий выезд ГБР. После поступления сигнала тревоги на наш пульт круглосуточного наблюдения на объект будет отправлена группа быстрого реагирования. Она состоит из сотрудников с удостоверениями 6-го разряда (применение служебного огнестрельного оружия). Среднее время прибытия группы до 7 минут. При подключении вашего объекта к нашей дежурной части мы проведем бесплатный осмотр объекта, выявим все его уязвимые места и устраним их посредством установки современного оборудования, которое позволит зафиксировать несанкционированное проникновение. Мы применяем как радио-волновую, так и GSM-систему мониторинга, имеем собственный отдел монтажа и обслуживания охранно-пожарной сигнализации, системы контроля доступа, видеонаблюдения. Ежемесячный платеж за услуги охраны: Гаражи - 700 руб Квартиры - 1000 руб. Офисы, магазины - от 1500 руб. Коттеджи, частные дома - от 1500 руб.",
    } as ServiceBlockType,
    {
        name: "Охранная сигнализация",
        priceName1: "Абонентская плата:",
        price1: "1 000 ₽ / мес.",
        priceName2: "Оборудование:",
        price2: "7 000 ₽",
        modalPrice1: "Стоимость обслуживания от 1 000 руб./мес.",
        modalPrice2: "Стоимость оборудования от 7 000 руб.",
        description: "Обеспечение безопасности при помощи современных систем охранно-пожарной и тревожной сигнализации предполагает, в том числе, последующий выезд ГБР. После поступления сигнала тревоги на наш пульт круглосуточного наблюдения на объект будет отправлена группа быстрого реагирования. Она состоит из сотрудников с удостоверениями 6-го разряда (применение служебного огнестрельного оружия). Среднее время прибытия группы до 7 минут. При подключении вашего объекта к нашей дежурной части мы проведем бесплатный осмотр объекта, выявим все его уязвимые места и устраним их посредством установки современного оборудования, которое позволит зафиксировать несанкционированное проникновение. Мы применяем как радио-волновую, так и GSM-систему мониторинга, имеем собственный отдел монтажа и обслуживания охранно-пожарной сигнализации, системы контроля доступа, видеонаблюдения. Ежемесячный платеж за услуги охраны: Гаражи - 700 руб Квартиры - 1000 руб. Офисы, магазины - от 1500 руб. Коттеджи, частные дома - от 1500 руб.",
    } as ServiceBlockType,

];