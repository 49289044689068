import {H4, IconMail, IconPhone, TextM, TypicalFooter} from "@mryaros/mryaros-design-system";
import {Link} from "react-router-dom";
import React from "react";
import {IconTelegram, IconVk} from "./icons/Icons";

export const ApplicationFooter = React.memo(function ApplicationFooter() {
    const mainBlocks = (
        <>
            <div>
                <H4 className="margin-no">ПОЛЕЗНО</H4>
                <div className="margin-t-16"><Link to="/#questions">Вопросы и ответы</Link></div>
            </div>
            <div>
                <H4 className="margin-no">КОНТАКТЫ</H4>
                <TextM className="margin-t-16 margin-b-8 flex-row"><IconPhone className="margin-r-8" /> +7 (952) 330-19-60</TextM>
                <TextM className="margin-b-8 flex-row"><IconPhone className="margin-r-8" /> +7 (950) 441-11-02</TextM>
                <TextM className="margin-b-8 flex-row"><IconMail className="margin-r-8" /> bulat.security@bk.ru</TextM>
            </div>
            <div>
                <H4 className="margin-no">РЕЖИМ РАБОТЫ</H4>
                <TextM className="margin-t-16 margin-b-8"><b>ПН - СБ </b> 9.00 - 18.00</TextM>
                <TextM className="margin-b-8"><b>ВС </b>- Выходной</TextM>
                <TextM className="margin-b-8"><b>Дежурная часть </b>- Круглосуточно</TextM>

                <TextM className="margin-t-32 margin-b-8">
                    <a href="https://bulat-perm.ru/files/bulat_kommercheskoe.pdf" target="_blank" style={{textDecorationLine: "underline"}}>Коммерческое предложение</a>
                </TextM>
                <a href="https://bulat-perm.ru/files/bulat_prezentatsia.pdf" target="_blank" style={{textDecorationLine: "underline"}}>Презентация об организации</a>
            </div>
            <div>
                <H4 className="margin-no">МЫ В СОЦ. СЕТЯХ</H4>
                <div className="margin-t-16">
                    <a href="https://vk.com/bulat.security" target="_blank" rel="noreferrer" className="margin-r-16"><IconVk /></a>
                    <a href="https://t.me/Bulat_ohrana" target="_blank" rel="noreferrer"><IconTelegram /></a>
                </div>
            </div>
        </>
    );
    return (
        <TypicalFooter mainBlocks={mainBlocks} />
    );
});